import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';

@Component({
    selector: 'app-testimonials',
    templateUrl: './testimonials.component.html',
    styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

    slides = [
        {
            name: this.language.translate.instant("Pascal Fauchille, Saint-Gobain Flat Glass H&S Technical Support Director"),
            job: this.language.translate.instant("« Glass handling in the working environment can present serious risks at any time when safety measures are not followed. To raise awareness of potential accidents, we asked Immersive Factory to reproduce the glazing storage site in virtual reality. Through the use of a VR headset, our team is able to preventively analyse dangerous situations and risky behaviour. This type of training benefits both our new and older employees, as it makes it possible to continuously educate them through short, repetitive sessions allowing us to maintain a high level of skills. The deployment of this technology is also much quicker to implement compared to a traditional training course, which is great! »"),
            logo: "assets/logos-carousels/saint-gobain.png"
        },
        {
            name: this.language.translate.instant("IMT Mines Albi Alumni"),
            job: this.language.translate.instant("« Well thought out tool, ergonomic and with interesting features to organize a meeting or a conference in virtual reality that changes from the ordinary! »"),
            logo: "assets/logos-carousels/imt-mines-albi-alumni.png"
        },
        {
            name: this.language.translate.instant("Egis created and used the VR training"),
            job: this.language.translate.instant("« In less than 6 months, EGIS was able to implement a new training program that is a game changer. Thanks to the training modules supplied through our partner IMMERSIVE FACTORY: zero accidents on the motorway is our goal. »"),
            logo: "assets/logos-carousels/egis.png"
        },
        {
            name: this.language.translate.instant("FM Logistic EHS Manager"),
            job: this.language.translate.instant("« It was important for FM to be able to keep up with the times, with an innovative product in the service of safety. »"),
            logo: "assets/logos-carousels/fmlogistic.png"
        },
        {
            name: this.language.translate.instant("Givaudan EHS manager - Ingredient and flavor company"),
            job: this.language.translate.instant("« Play and competition are sources of motivation and pride. You can have strong ambitions but you also need intermediary goals to evaluate your personal fulfilment (especially since COVID). I think Immersive Factory offers the ideal solution: in a few minutes of play, you can go hunting for risks and have an immediate sense of success with each identified dangerous situation. »"),
            logo: "assets/logos-carousels/givaudan.png"
        },
        {
            name: this.language.translate.instant("Chimirec - QSE Animator"),
            job: this.language.translate.instant("“I use VR about once a week as part of my security welcome for newcomers. I get positive feedback with a better understanding of the risks in general. I recommend Immersive Factory because it’s a good experience that can be enjoyed in any industry.”"),
            logo: "assets/logos-carousels/chimirec.png"
        },
        {
            name: this.language.translate.instant("Vallourec - Senior HSE Manager"),
            job: this.language.translate.instant("“With Immersive Factory, we have developed a specific module to make our operators aware of a major risk at Vallourec. The major advantage of VR is that it allows all employees to be involved in their training.”"),
            logo: "assets/logos-carousels/vallourec.png"
        },
        {
            name: this.language.translate.instant("R.A.S Intérim - QSE Manager"),
            job: this.language.translate.instant("“We use VR for the animation of 10 to 15 safety days per year on average in our branches and at our customers. We also use it for the integration of our employees at the head office in Lyon. Employees appreciate the immersive and fun side of the training. I higly recommend Immersive Factory !”"),
            logo: "assets/logos-carousels/ras.png"
        },
        {
            name: this.language.translate.instant("COLAS RAIL - Head of the Campus FER Training Organization"),
            job: this.language.translate.instant("“The solution is interesting because it’s portable. During ongoing train driver assesments, the solution can be used at the customer site. The solution is close to reality, quick to start and use and it offers a new way of learning.”"),
            logo: "assets/logos-carousels/colas-rail.png"
        },
        {
            name: this.language.translate.instant("Soletanche Bachy International - Deputy EHS Manager"),
            job: this.language.translate.instant("“Between September and December 2019, I deployed the Immersive Factory solution in Malaysia, Singapore, New Zealand on our projects at the time as well as for a few people from headquarters. I was able to pass about 300 people during this period. Currenly, I would say that I am at a total of 400. Each time I have been able to present and carry out VR workshops, the feedback has always been positive and enthusiastic. They are impressed.”"),
            logo: "assets/logos-carousels/soletanche.png"
        },
        {
            name: this.language.translate.instant("DS Smith - EHS Manager Rochechouart site"),
            job: this.language.translate.instant("“VR allows greater immersion and above all the employee is an actor during the session. Almost all employees appreciate the tool (even the most reluctant at first).”"),
            logo: "assets/logos-carousels/ds-smith.png"
        },
        {
            name: this.language.translate.instant("TASQ-OM - Training and Recruitment Manager"),
            job: this.language.translate.instant("“VR allows us to put our trainees in an “almost real” condition when faced with a problem to be solved. Much more meaningful than a video or a simple PowerPoint presentation. Finding yourself in a situation is much more meaningful for our trainees and the acquisition of the skills concerned is done more quickly. In our professional training sector, a classic day between 4 walls now becomes active.”"),
            logo: "assets/logos-carousels/tasq-om.png"
        },
        {
            name: this.language.translate.instant("Veolia - Director of health and safety audits, methods and innovations"),
            job: this.language.translate.instant("“These trainings are a real added value to contextualize the risks and have a shared awareness of the major risks of the company.”"),
            logo: "assets/logos-carousels/veolia.png"
        },
        {
            name: this.language.translate.instant("EDF CIST-INGEUM Unit - Electronics Engineer, Head of Innovation Project and Innovation Leader"),
            job: this.language.translate.instant("“From the start, I felt a relationship of trust and transparency with the Immersive Factory team. Moreover, the fact that the CEOs come from the training and safety world reassured me greatly. The project managers and developers are also top-notch, always attentive to our needs and very responsive.”"),
            logo: "assets/logos-carousels/edf.png"
        },
        {
            name: this.language.translate.instant("Paul Sabatier IUT AUCH - Second-year student in BUT HSE (Hygiene, Safety, Environment)"),
            job: this.language.translate.instant("“This technology allows for a deeper exploration of topics even when we haven't been able to go on-site, as it's not always possible. These are topics that as a QHSE manager, we will nonetheless need to address. It also enables more individualized projects without complicating deployment for teachers, given the multitude of topics covered by the Immersive Factory workshops.”"),
            logo: "assets/logos-carousels/iut-occitanie-auch.png"
        },
        {
            name: this.language.translate.instant("Chryso - QHSE Director"),
            job: this.language.translate.instant("“I would indeed recommend Immersive Factory because it's an appealing medium to convey the right messages.”"),
            logo: "assets/logos-carousels/chryso.png"
        },
        {
            name: this.language.translate.instant("Riva Group - HSE Coordinator"),
            job: this.language.translate.instant("“The 3D representation of a workspace, objects, and individuals allows employees to be active participants and offers them an experience, an assimilation of knowledge they won't forget. They come out very satisfied! It revolutionizes traditional and passive training methods, such as slide show presentations and the like.”"),
            logo: "assets/logos-carousels/riva.png"
        },
        {
            name: this.language.translate.instant("Merck Group - HSE Management Apprentice"),
            job: this.language.translate.instant("“The employees were much more dynamic than during the PPT sessions. The exchange was also much more pleasant; we were able to conduct safety training sessions with joy and good spirits! [...] They retained much more information and told us that we should continue to develop training using this tool.”"),
            logo: "assets/logos-carousels/merck.png"
        },
        {
            name: this.language.translate.instant("Supply Chain Coca-Cola Europacific Partners - Learning and Development Manager"),
            job: this.language.translate.instant("“The choice of the SafetyBox VR© came quite naturally as this solution offers support for future trainers and an event day that promotes the technology and its benefits. The feedback has been excellent! Employees at all levels are thrilled to learn differently, using modern and engaging technologies.”"),
            logo: "assets/logos-carousels/coca-cola.png"
        },
        {
            name: this.language.translate.instant("Dalkia - QHSE Correspondent"),
            job: this.language.translate.instant("“I was won over by the playful and innovative aspect of VR which allows for renewing the discourse and the support of safety talks and brings an impactful touch depending on how the accident is simulated.”"),
            logo: "assets/logos-carousels/dalkia.png"
        },
        {
            name: this.language.translate.instant("Narbonne Occupational Health Service - Prevention ingineer and head of Prevention Relays"),
            job: this.language.translate.instant("“Immersive Factory is a company that met our expectations because it has already worked with occupational health services and it knows our challenges and our vision well.”"),
            logo: "assets/logos-carousels/sist.png"
        },
        {
            name: this.language.translate.instant("Kimberly-Clark - Safety animator"),
            job: this.language.translate.instant("“We chose the SafetyBox VR for the variety of modules offered, the support provided by the team, and the price. [...] It allows us to raise awareness among employees and animate our safety campaigns.”"),
            logo: "assets/logos-carousels/kimberly-clark.png"
        },
        {
            name: this.language.translate.instant("Veolia - Health and Safety Prevention Officer"),
            job: this.language.translate.instant("“In safety, it is possible to act on the cognitive biases of our employees and change their way of approaching dangerous situations. I found, in Immersive Factory, a solution adapted to our activities and a real lever of action for changing our risky behaviors.”"),
            logo: "assets/logos-carousels/veolia.png"
        },
        {
            name: this.language.translate.instant("International Flavors & Fragrances - Associate Scientist"),
            job: this.language.translate.instant("“The experience is more playful and sensory. We are truly immersed in a world. It’s very interesting because we are truly actors in a scenario instead of remaining passive in front of a presentation on screen. This tool meets our need to evolve our safety culture.”"),
            logo: "assets/logos-carousels/iff.png"
        },
        {
            name: this.language.translate.instant("Granulats Vicat - Quality, Safety, Environment Coordinator"),
            job: this.language.translate.instant("“We opted for this new form of prevention approach which matches the expectations of our employees more closely (new way of understanding risks, new technology…).”"),
            logo: "assets/logos-carousels/vicat.png"
        },
        {
            name: this.language.translate.instant("RTC Antwerpen - Consultant and XR-Coordinator"),
            job: this.language.translate.instant("“I would definitely recommend Immersive Factory. Our schools are very excited about their trainings. It helps them to make students aware of potential dangers in their worksites. Immersive Factory provides great trainings and support. The implementation is very easy and the apps work smoothly.”"),
            logo: "assets/logos-carousels/rtc-antwerpen.png",
        },
        {
            name: this.language.translate.instant("Atalian - QHSE Coordinator"),
            job: this.language.translate.instant("“Virtual reality helps develop employee awareness of different types of dangerous situations. It’s a playful and collaborative format for safety animation, innovative compared to the usual talks. Moreover, the 'Hazard Spotting - Office' module is visually beautiful and very appreciated by the staff. Additionally, Immersive Factory is very responsive in case of technical problems with the VR headset.”"),
            logo: "assets/logos-carousels/atalian.png",
        },
        {
            name: this.language.translate.instant("GT Logistics - QHSE Manager"),
            job: this.language.translate.instant("“It’s a way of raising awareness in a different way, but also of getting people more interested in the subject of safety, which is sometimes difficult for some of our team members to grasp.”"),
            logo: "assets/logos-carousels/gt-logistics.png",
        },
        {
            name: this.language.translate.instant("SPSTI81 - EHS Engineer"),
            job: this.language.translate.instant("“Virtual reality allows you to experience total immersion, to feel as if you were in the company, since there are many Immersive Factory scenarios that make this possible. As a result, our employees feel as though they’re in their own company, putting into practice a number of risk-prevention initiatives. Thus, this make it easier to raise awareness.”"),
            logo: "assets/logos-carousels/spsti81.png",
        },
        {
            name: this.language.translate.instant("Miramas Town Hall - Prevention Advisor"),
            job: this.language.translate.instant("“Virtual reality allows, without mobilizing many resources, to immerse oneself in a professional situation with its risks and difficulties. It allows learning and making a lasting impression in a fun way.”"),
            logo: "assets/logos-carousels/miramas.png",
        },
        {
            name: this.language.translate.instant("Vilquin - Quality, Hygiene, Safety, and Environment Manager"),
            job: this.language.translate.instant("“Virtual reality meets a need for QHSE awareness and puts our employees in real-life conditions. There are fewer accidents and more awareness from the employees.”"),
            logo: "assets/logos-carousels/vilquin.png",
        },
        {
            name: this.language.translate.instant("Groupama - In charge of public relations and mutualistic action"),
            job: this.language.translate.instant("“SafetyBox VR® appealed to us because it’s turnkey, with exercises integrated directly, and there’s good after-sales service.”"),
            logo: "assets/logos-carousels/groupama.png",
        },
        {
            name: this.language.translate.instant("Qualipac - EHS engineer"),
            job: this.language.translate.instant("“We received excellent feedback from our employees, who have significantly improved their daily risk detection, which helps us better manage our risks.”"),
            logo: "assets/logos-carousels/qualipac.png",
        },
        {
            name: this.language.translate.instant("Nexans - EHS technician"),
            job: this.language.translate.instant("“Virtual reality is a fun and effective tool for delivering messages! This is very interesting for our training sessions.”"),
            logo: "assets/logos-carousels/nexans.png",
        },
        {
            name: this.language.translate.instant("Sysco - HR & Skills Development Manager"),
            job: this.language.translate.instant("“Virtual reality allows employees to experience realistic safety scenarios without real risk. This promotes better retention of information and practical skills. VR simulations can help identify potential hazards in a work environment, thereby improving planning and preventive measures.”"),
            logo: "assets/logos-carousels/sysco.png",
        },
        {
            name: this.language.translate.instant("EDF’s Supply Chain Division - Safety Engineer"),
            job: this.language.translate.instant("“We’ve received positive feedback from employees. It’s relatively easy to use, and the fact that the headset is autonomous is incredibly practical. When I take it to a site, I don’t have to worry about where I’ll set it up.”"),
            logo: "assets/logos-carousels/edf.png",
        },
        {
            name: this.language.translate.instant("Briand - Quality, Safety, and Environment Manager"),
            job: this.language.translate.instant("“We observed increased attention during the workshops and, therefore, better understanding of the messages conveyed. The workshop resonates with participants, allowing us to reference the hazardous situations encountered and apply them to employees’ daily routines.”"),
            logo: "assets/logos-carousels/briand.png",
        },
        {
            name: this.language.translate.instant("ArcelorMittal - Health & Safety Environment Coordinator"),
            job: this.language.translate.instant("“The use of virtual reality for safety was very appreciated during our health/safety day. The two applications chosen for the working at height activity were a real success. People who are not receptive or sensitive to this type of technology have played the game and tried their hand at this technology. Your colleague in charge of the animation was very available, listened to our staff and allowed the majority of people registered for this activity to test this technology. The introduction of this technology in the field of safety greatly contributed to the success of this day on our working at height theme.”"),
            logo: "assets/logos-carousels/arcelor-mittal.png",
        }
    ]

    constructor(
        private language: LanguageService
    ) { }

    ngOnInit(): void {
    }

}
